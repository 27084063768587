import React, { useState } from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {Container, Row, Col} from '../../../../shared-ui/components/ui/wrapper'
import Heading from '../../../../shared-ui/components/ui/heading'
import Text from '../../../../shared-ui/components/ui/text'
import Button from '../../../../shared-ui/components/ui/button'
import ContactForm from '../../../../shared-ui/components/forms/contact-form'
import {ContactFormWrapper, LeftBox} from './index.style'
import Form, { FormGroup, Input, Textarea, Error } from '../../../../shared-ui/components/ui/form'

const ContactFormSection = ({headingStyle, textStyle}) => {

    // https://react-hook-form.com/migrate-v6-to-v7/

    const portalId = "4031661";
    const formId = "4085dd4e-1c5e-4c2b-b71a-84be4a25bc41"
    let formUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

	const { register, handleSubmit, formState: { errors } } = useForm({
		mode: "onBlur"
	})

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	});
	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
		}
	};

	const onSubmit = (data, e) => {
		const form = e.target;
		setServerState({ submitting: true });
		axios({
			method: "post",
			url: formUrl,
			data: 
            { 
                "fields": [
                {
                  "name": "email",
                  "value": data.email
                },
                {
                  "name": "firstname",
                  "value": data.name
                },
                {
                    "name": "lastname",
                    "value": data.lastname
                },
                {
                    "name": "message",
                    "value": data.message
                }
              ]/*,
              "context": {
                "hutk": ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                "pageUri": "www.example.com/page",                
                "pageName": "Example page"
              }*/
              }
		})
			.then(r => {
				handleServerResponse(true, "!Gracias por contactanos! Nos pondremos en contacto contigo a la brevedad posible.", form);
			})
			.catch(r => {
				handleServerResponse(false, r.response.data.error, form);
			});
	}

    return (
        <ContactFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        <LeftBox>
                            <Heading {...headingStyle}>Para más información, <br/>usa esta <span>forma de contacto</span><br/> o <span>llámanos</span> al<br/> <span>+52 (614) 423-6357</span></Heading>
                            <Text {...textStyle}>Nos encontramos disponibles de Lunes a Viernes de 9 AM a 6 PM Horario de Chihuahua. Para correos electrónicos, generalmente respondemos en menos de dos horas.</Text>
                        </LeftBox>
                    </Col>
                    <Col lg={6}>
                        {/* Start of Form */}
                        <Form onSubmit={handleSubmit(onSubmit)}>                    
                            <Row gutters={{ lg: 20 }}>
                                <Col lg={6}>
                                    <FormGroup mb="20px">
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nombre *"
                                            {...register('name', { required: 'Nombre es requerido' })}
                                        />
                                        <Error>{errors.name && errors.name.message}</Error>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>                                
                                    <FormGroup mb="20px">
                                        <Input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder="Apellido(s)"
                                            {...register('lastname')}
                                        />
                                        <Error>{errors.lastname && errors.lastname.message}</Error>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                <FormGroup mb="20px">
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email *"
                                            {...register('email', {
                                                required: 'Email es requerido',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Email es inválido."
                                                }
                                            })}
                                        />
                                        <Error>{errors.email && errors.email.message}</Error>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup mb="30px">
                                        <Textarea
                                            name="message"
                                            id="message"
                                            placeholder="¿Como te podemos ayudar?"
                                            {...register('message', {
                                                required: 'Message is required',
                                                maxLength: {
                                                    value: 250,
                                                    message: "Maxima longitud es 250"
                                                },
                                                minLength: {
                                                    value: 10,
                                                    message: "Minina longitud es 10"
                                                }
                                            })}
                                        ></Textarea>
                                        <Error>{errors.message && errors.message.message}</Error>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Button type="submit" disabled={serverState.submitting}>Enviar Mensaje</Button>
                                    {serverState.status && (
                                        <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                                            {serverState.status.msg}
                                        </p>
                                    )}
                                </Col>
                            </Row>                        
                        </Form>
                    {/* End of Form */}
                    </Col>
                </Row>
            </Container>
        </ContactFormWrapper>
    )
}

ContactFormSection.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

ContactFormSection.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px',
        color: '#696969'
    }
}

export default ContactFormSection;